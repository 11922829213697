import { createSelector } from "@reduxjs/toolkit";

import { AdaApiSlice } from "slices";

interface GeneratedTopicV2Response {
  _id: string;
  created: number;
  created_by: string;
  description: string | null;
  is_deleted: boolean;
  is_hidden: boolean;
  is_leaf: boolean;
  last_seen: number;
  parent_topic: string | null;
  title: string;
  updated: number;
  updated_by: string;
}

export interface GeneratedTopicsV2 {
  _id: string;
  created: Date;
  createdBy: string;
  description: string;
  isDeleted: boolean;
  isHidden: boolean;
  isLeaf: boolean;
  lastSeen: Date;
  parentTopic: string | null;
  title: string;
  updated: Date;
  updatedBy: string;
}

export interface GeneratedTopicsV2Report {
  columnNames: string[];
  data: string[][];
  meta: {
    name: string;
    value: string;
  }[];
}

export interface GeneratedTopicsFormattedV2Report {
  columnNames: string[];
  data: string[][];
  meta: {
    [k: string]: string | number;
  };
}

const generatedTopicsV2Api = AdaApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratedTopics: builder.query({
      query: () => ({
        url: "/generated_topics/v2/",
        method: "GET",
      }),
      transformResponse: (response: {
        data: GeneratedTopicV2Response[];
      }): GeneratedTopicsV2[] =>
        response.data.map((topic) => ({
          _id: topic._id,
          created: new Date(topic.created * 1000),
          createdBy: topic.created_by,
          description: topic.description ?? "",
          isDeleted: topic.is_deleted,
          isHidden: topic.is_hidden,
          isLeaf: topic.is_leaf,
          lastSeen: new Date(topic.last_seen * 1000),
          parentTopic: topic.parent_topic,
          title: topic.title,
          updated: new Date(topic.updated * 1000),
          updatedBy: topic.updated_by,
        })),
      providesTags: ["GeneratedTopics"],
    }),
    getGeneratedTopicByConversationId: builder.query({
      query: ({ id }) => ({
        url: `/generated_topics/v2/topic/${id}`,
        method: "GET",
      }),
      transformResponse: (response: {
        data: GeneratedTopicV2Response;
      }): GeneratedTopicsV2 => ({
        _id: response.data._id,
        created: new Date(response.data.created * 1000),
        createdBy: response.data.created_by,
        description: response.data.description ?? "",
        isDeleted: response.data.is_deleted,
        isHidden: response.data.is_hidden,
        isLeaf: response.data.is_leaf,
        lastSeen: new Date(response.data.last_seen * 1000),
        parentTopic: response.data.parent_topic,
        title: response.data.title,
        updated: new Date(response.data.updated * 1000),
        updatedBy: response.data.updated_by,
      }),
    }),
    getGeneratedTopicsReport: builder.query({
      query: ({ params }) => ({
        url: "/ares/tabular/reports/generatedTopicsV2",
        method: "GET",
        params,
      }),
      transformResponse: (
        response: GeneratedTopicsV2Report,
      ): GeneratedTopicsFormattedV2Report => ({
        ...response,
        meta: {
          ...response.meta.reduce(
            (obj, { name, value }) => ({ ...obj, [name]: value }),
            {},
          ),
          page: parseInt(
            (response.meta.find((m) => m.name === "page")?.value ??
              1) as unknown as string,
            10,
          ),

          totalPages: parseInt(
            (response.meta.find((m) => m.name === "totalPages")?.value ??
              1) as unknown as string,
            10,
          ),

          totalCount: parseInt(
            (response.meta.find((m) => m.name === "totalCount")?.value ??
              1) as unknown as string,
            10,
          ),
        },
      }),
      providesTags: ["GeneratedTopics"],
    }),
    getGeneratedTopicDetailsReport: builder.query({
      query: ({ params }) => ({
        url: "/ares/tabular/reports/topicDetailsV2",
        method: "GET",
        params,
      }),
      transformResponse: (
        response: GeneratedTopicsV2Report,
      ): GeneratedTopicsFormattedV2Report => ({
        ...response,
        meta: {
          ...response.meta.reduce(
            (obj, { name, value }) => ({ ...obj, [name]: value }),
            {},
          ),
          page: parseInt(
            (response.meta.find((m) => m.name === "page")?.value ??
              1) as unknown as string,
            10,
          ),

          totalPages: parseInt(
            (response.meta.find((m) => m.name === "totalPages")?.value ??
              1) as unknown as string,
            10,
          ),

          totalCount: parseInt(
            (response.meta.find((m) => m.name === "totalCount")?.value ??
              1) as unknown as string,
            10,
          ),
        },
      }),
      providesTags: ["GeneratedTopics"],
    }),
    createTopic: builder.mutation({
      query: (data) => ({
        url: "/generated_topics/v2/",
        method: "POST",
        data,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    editTopic: builder.mutation({
      query: ({ id, body }) => ({
        url: `/generated_topics/v2/${id}`,
        method: "PATCH",
        data: body,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    hideTopic: builder.mutation({
      query: (data) => ({
        url: "/generated_topics/v2/hide",
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    unhideTopic: builder.mutation({
      query: (data) => ({
        url: "/generated_topics/v2/unhide",
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    groupTopic: builder.mutation({
      query: (data) => ({
        url: "/generated_topics/v2/group",
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    ungroupTopic: builder.mutation({
      query: (data) => ({
        url: "/generated_topics/v2/ungroup",
        method: "PATCH",
        data,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    changeTopic: builder.mutation({
      query: ({ id, body }) => ({
        url: `generated_topics/v2/change/${id}`,
        method: "PATCH",
        data: body,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    updateGeneratedTopicsIsOn: builder.mutation({
      query: ({ isOn }) => ({
        url: `generated_topics/v2/is_generated_topics_on`,
        method: "PATCH",
        data: { isOn },
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    getGeneratedTopicsIsOn: builder.query({
      query: () => ({
        url: "generated_topics/v2/is_generated_topics_on",
        method: "GET",
      }),
      transformResponse: (response: { data: boolean }) => response.data,
      providesTags: ["GeneratedTopics"],
    }),
  }),
});

export const SelectAllGeneratedTopics = createSelector(
  generatedTopicsV2Api.endpoints.getGeneratedTopics.select({}),
  (res) => res.data ?? [],
);

export const {
  useGetGeneratedTopicsQuery,
  useGetGeneratedTopicByConversationIdQuery,
  useGetGeneratedTopicsReportQuery,
  useGetGeneratedTopicDetailsReportQuery,
  useCreateTopicMutation,
  useEditTopicMutation,
  useHideTopicMutation,
  useUnhideTopicMutation,
  useGroupTopicMutation,
  useUngroupTopicMutation,
  useChangeTopicMutation,
  useUpdateGeneratedTopicsIsOnMutation,
  useGetGeneratedTopicsIsOnQuery,
} = generatedTopicsV2Api;
