import { primitives, tokens } from "@adasupport/byron";
import React, { type ReactNode, useEffect } from "react";
import styled from "styled-components";

import { type JSONData } from "services/helpers";

import { concatJmesPath } from "./helpers";

type JSONPrimitiveType = "string" | "number" | "boolean" | "null";

const S = {
  Property: styled.div<{ topLevel: boolean }>`
    margin-left: ${(p) => (p.topLevel ? 0 : 16)}px;
    line-height: 26px;
    font-size: 14px;
    font-family: Menlo, Monaco, Consolas, "Lucida Console", monospace;
    position: relative;
  `,
  PropertyName: styled.span<{ type: string | number }>`
    font-weight: 700;
    margin-right: 4px;
    color: ${(p) => {
      switch (p.type) {
        case "number":
          return primitives.palette.red600;
        default:
          return tokens.colors.text.main;
      }
    }};
  `,
  PropertyColon: styled.span`
    font-weight: 400;
    color: ${tokens.colors.text.muted};
    margin-right: 4px;
  `,
  PropertyPrimitiveValue: styled.span<{
    type: JSONPrimitiveType;
  }>`
    color: ${(p) => {
      switch (p.type) {
        case "string":
          return primitives.palette.blue600;
        case "number":
          return primitives.palette.green600;
        case "boolean":
          return primitives.palette.orange700;
        case "null":
          return tokens.colors.text.muted;
        default:
          return tokens.colors.text.main;
      }
    }};
  `,
  Bracket: styled.span`
    color: ${tokens.colors.text.muted};
  `,
  PropertyHoverOverlay: styled.div`
    position: absolute;
    top: 0;
    left: -8px;
    right: 0;
    height: 100%;
    background: ${primitives.palette.slate200};
    display: flex;
    justify-content: right;
    align-items: flex-start;
  `,
  PropertyHoverOverlayContent: styled.div`
    position: relative;
    z-index: 1;
    background: inherit;
    padding: 0 0 0 8px;
  `,
  PropertyOverlayNamePath: styled.div`
    padding-right: 8px;
  `,
};

const Property = ({
  name,
  value,
  parentJmesPath,
  interactive = true,
  renderPropertyOverlayContent,
}: {
  name?: string | number;
  value: JSONData;
  parentJmesPath?: string;
  interactive?: boolean;
  renderPropertyOverlayContent?: (p: { jmesPath: string }) => ReactNode;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const jmesPath = concatJmesPath(parentJmesPath, name);

  const [hovered, setHovered] = React.useState(false);

  useEffect(() => {
    ref.current?.addEventListener("mouseover", (e) => {
      e.stopPropagation();

      if (interactive && jmesPath) {
        setHovered(true);
      }
    });

    ref.current?.addEventListener("mouseout", (e) => {
      e.stopPropagation();
      setHovered(false);
    });
  }, [jmesPath, interactive]);

  const renderValue = () => {
    if (Array.isArray(value)) {
      return value.map((v, i) => (
        <Property
          key={i}
          name={i}
          value={v}
          parentJmesPath={jmesPath}
          interactive={interactive}
          renderPropertyOverlayContent={renderPropertyOverlayContent}
        />
      ));
    }

    if (value && typeof value === "object") {
      return Object.entries(value).map(([k, v]) => (
        <Property
          key={k}
          name={k}
          value={v}
          parentJmesPath={jmesPath}
          interactive={interactive}
          renderPropertyOverlayContent={renderPropertyOverlayContent}
        />
      ));
    }

    if (value === null) {
      return (
        <S.PropertyPrimitiveValue type="null">null</S.PropertyPrimitiveValue>
      );
    }

    return (
      <S.PropertyPrimitiveValue type={typeof value as JSONPrimitiveType}>
        {JSON.stringify(value)}
      </S.PropertyPrimitiveValue>
    );
  };

  const brackets = (() => {
    if (Array.isArray(value)) {
      return "[]";
    }

    if (value && typeof value === "object") {
      return "{}";
    }

    return "";
  })();

  return (
    <S.Property topLevel={name === undefined} ref={ref}>
      {interactive && hovered && (
        <S.PropertyHoverOverlay>
          <S.PropertyHoverOverlayContent>
            {/* Either render the custom content or just the name path */}
            {renderPropertyOverlayContent ? (
              renderPropertyOverlayContent({ jmesPath })
            ) : (
              <S.PropertyOverlayNamePath>{jmesPath}</S.PropertyOverlayNamePath>
            )}
          </S.PropertyHoverOverlayContent>
        </S.PropertyHoverOverlay>
      )}

      {name !== undefined && (
        <>
          <S.PropertyName type={typeof name}>{name}</S.PropertyName>
          {typeof name === "string" && <S.PropertyColon>:</S.PropertyColon>}
        </>
      )}

      {brackets[0] && <S.Bracket>{brackets[0]}</S.Bracket>}

      {renderValue()}

      {brackets[1] && <S.Bracket>{brackets[1]}</S.Bracket>}
    </S.Property>
  );
};

export const JSONInspector = ({
  json,
  interactive = true,
  renderPropertyOverlayContent,
}: {
  json: JSONData;
  interactive?: boolean;
  renderPropertyOverlayContent?: (p: { jmesPath: string }) => ReactNode;
}) => (
  <Property
    value={json}
    interactive={interactive}
    renderPropertyOverlayContent={renderPropertyOverlayContent}
  />
);
